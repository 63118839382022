import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as USERApi from '../apis/USERApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  CircleImage,
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const TasksScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Earn, setEarn] = React.useState(0);
  const [ModalState, setModalState] = React.useState(false);
  const [Tasks, setTasks] = React.useState([
    { id: 1, title: 'Invite 5 frens', subTitle: '0/5 frens, +50 H' },
    { id: 1, title: 'Subscribe Hookah Telegram', subTitle: '+120 H' },
    { id: 1, title: 'Join Hookah Insta', subTitle: '+200 H' },
    { id: 1, title: 'Follow Hookah on X', subTitle: '+200 H' },
    { id: 1, title: 'Follow Hookah on Youtube', subTitle: '+200' },
    { id: 1, title: 'Task_1', subTitle: '+200' },
    { id: 1, title: 'Task_1', subTitle: '+200' },
  ]);
  const taskBtnText = isFinished => {
    if (isFinished) {
      return 'Claim';
    } else {
      return 'Start';
    }
  };
  const uSERPUTTASKCLAIMEDPUT = USERApi.usePUTTASKCLAIMEDPUT();
  const uSERPOSTTASKFINISHPOST = USERApi.usePOSTTASKFINISHPOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasBottomSafeArea={false}
      hasLeftSafeArea={false}
      hasRightSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.Theme_background },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: palettes.App.Theme_background,
            justifyContent: 'space-between',
            marginLeft: 10,
            marginRight: 10,
            paddingBottom: 10,
            paddingTop: 10,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth({ marginTop: 15 }, dimensions.width)}
        >
          <CircleImage size={60} source={Images['logo1']} />
        </View>
        {/* Text 2 */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: palettes.App.Theme_text_1,
              fontFamily: 'Aladin_400Regular',
              fontSize: 26,
              marginBottom: 10,
              marginTop: 10,
            }),
            dimensions.width
          )}
        >
          {'Tasks'}
        </Text>

        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: theme.colors.background.brand,
              fontFamily: 'Aladin_400Regular',
              fontSize: 18,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {
            "We'll reward you vimmediately with points after each task completion."
          }
        </Text>
      </View>

      <USERApi.FetchGETUSERTASKSGET
        handlers={{
          on4xx: fetchData => {
            try {
              navigation.navigate('WelcomeScreen');
            } catch (err) {
              console.error(err);
            }
          },
        }}
        userId={Constants['UserId']}
      >
        {({ loading, error, data, refetchGETUSERTASKS }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <SimpleStyleFlatList
              data={fetchData}
              horizontal={false}
              inverted={false}
              keyExtractor={(listData, index) =>
                listData?.id ??
                listData?.uuid ??
                index?.toString() ??
                JSON.stringify(listData)
              }
              keyboardShouldPersistTaps={'never'}
              listKey={'Liri1rsX'}
              nestedScrollEnabled={false}
              numColumns={1}
              onEndReachedThreshold={0.5}
              renderItem={({ item, index }) => {
                const listData = item;
                return (
                  <>
                    {/* View 7 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: 'stretch',
                          backgroundColor: palettes.App.Theme_background,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App.Theme_background_2,
                            borderBottomWidth: 1,
                            borderColor: theme.colors.border.brand,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: 12,
                            paddingBottom: 18,
                            paddingLeft: 15,
                            paddingRight: 15,
                            paddingTop: 18,
                          },
                          dimensions.width
                        )}
                      >
                        <View>
                          <CircleImage
                            size={48}
                            source={{
                              uri: `https://hookaah-bot.azurewebsites.net/photo?name=${listData?.image}`,
                            }}
                          />
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1, marginLeft: 10, marginRight: 10 },
                            dimensions.width
                          )}
                        >
                          {/* Name ~ */}
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.background.brand,
                                fontFamily: 'Aladin_400Regular',
                                fontSize: 18,
                                marginTop: 4,
                                opacity: 1,
                              },
                              dimensions.width
                            )}
                          >
                            {listData?.title}
                          </Text>
                          {/* Message ~ */}
                          <Text
                            accessible={true}
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.background.brand,
                                fontFamily: 'Aladin_400Regular',
                                fontSize: 16,
                                marginTop: 4,
                                opacity: 1,
                              },
                              dimensions.width
                            )}
                          >
                            {listData?.description}
                          </Text>
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'flex-end' },
                            dimensions.width
                          )}
                        >
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  if (listData?.isFinished === true) {
                                    (
                                      await uSERPUTTASKCLAIMEDPUT.mutateAsync({
                                        taskId: listData?.id,
                                        userId: Constants['UserId'],
                                      })
                                    )?.json;
                                    setGlobalVariableValue({
                                      key: 'TASK_EARN',
                                      value: listData?.earn,
                                    });
                                    setModalState(true);
                                  } else {
                                    await WebBrowser.openBrowserAsync(
                                      `${listData?.url}`
                                    );
                                    (
                                      await uSERPOSTTASKFINISHPOST.mutateAsync({
                                        taskId: listData?.id,
                                        userId: Constants['UserId'],
                                      })
                                    )?.json;
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            activeOpacity={1}
                            disabled={listData?.isClaimed}
                            disabledOpacity={0.2}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button']
                                  .style,
                                {
                                  borderRadius: 20,
                                  fontFamily: 'System',
                                  fontSize: 14,
                                  fontWeight: '600',
                                  width: 55,
                                }
                              ),
                              dimensions.width
                            )}
                            title={`${taskBtnText(listData?.isFinished)}`}
                          />
                        </View>
                      </View>
                      <Divider
                        {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                        color={theme.colors.text.medium}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.DividerStyles(theme)['Divider'].style,
                            { height: 2 }
                          ),
                          dimensions.width
                        )}
                      />
                    </View>
                  </>
                );
              }}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'auto',
                  flex: 1,
                  flexBasis: 1,
                  gap: 0,
                  marginLeft: 10,
                  marginRight: 10,
                },
                dimensions.width
              )}
            />
          );
        }}
      </USERApi.FetchGETUSERTASKSGET>
      {/* BottomNavigation_Bar */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'stretch',
            backgroundColor: palettes.App.Theme_background,
            flexDirection: 'row',
            height: '10%',
            justifyContent: 'space-between',
            position: 'relative',
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App.Theme_background_2,
              borderBottomLeftRadius: 30,
              borderBottomRightRadius: 30,
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginBottom: 25,
              marginLeft: 10,
              marginRight: 10,
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('MainScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'MaterialCommunityIcons/bank'}
              />
            </View>
          </Touchable>
          {/* Touchable 2 */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('TasksScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'MaterialCommunityIcons/clipboard-check-multiple-outline'}
              />
            </View>
          </Touchable>
          {/* Touchable 3 */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('MarketScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'SimpleLineIcons/social-dropbox'}
              />
            </View>
          </Touchable>
          {/* Touchable 4 */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('UserScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'Feather/user'}
              />
            </View>
          </Touchable>
        </View>
      </View>

      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        visible={ModalState}
      >
        <View
          style={StyleSheet.applyWidth(
            { backgroundColor: '"rgba(0, 0, 0, 0.42)"', flex: 1 },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App.Theme_background_2,
                height: '40%',
                marginLeft: 20,
                marginRight: 20,
                top: '25%',
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end', right: 10, top: 10 },
                dimensions.width
              )}
            >
              <IconButton
                onPress={() => {
                  try {
                    setModalState(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                color={palettes.Brand.Surface}
                icon={'AntDesign/close'}
                size={26}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              <CircleImage size={120} source={Images['congratsgif1']} />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App['Custom Color_6'],
                      fontFamily: 'Aladin_400Regular',
                      fontSize: 32,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Congratulation'}
              </Text>
              {/* Text 2 */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.border.brand,
                      fontFamily: 'Aladin_400Regular',
                      fontSize: 24,
                      marginTop: 20,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'You earn '}
                {Constants['TASK_EARN']}
                {' Hookah coin !'}
              </Text>
            </View>
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(TasksScreen);
