import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as USERApi from '../apis/USERApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import getQueryProfileImagePath from '../global-functions/getQueryProfileImagePath';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import parseBoolean from '../utils/parseBoolean';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  CircleImage,
  LinearGradient,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { ImageBackground, Text, View } from 'react-native';

const RegisterScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [TELEGRAM_ID, setTELEGRAM_ID] = React.useState(0);
  const [TELEGRAM_USERNAME, setTELEGRAM_USERNAME] = React.useState(null);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const getQueryTgId = () => {
    const searchParams = new URLSearchParams(location.search);
    const TgId = searchParams.get('TgId');

    if (TgId) {
      return TgId;
    } else {
      return;
    }
  };

  const getQueryTgUsername = () => {
    const searchParams = new URLSearchParams(location.search);

    const TgUsername = searchParams.get('TgUsername');

    if (TgUsername) {
      return TgUsername;
    } else {
      return 'No value';
    }
  };

  const getQueryProfileImagePath = () => {
    const searchParams = new URLSearchParams(location.search);
    const ProfileImagePath = searchParams.get('profileImagePath');

    if (ProfileImagePath) {
      return ProfileImagePath;
    } else {
      return 'No value';
    }
  };
  const uSERREGISTERUSERPOST = USERApi.useREGISTERUSERPOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasLeftSafeArea={false}
      hasRightSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images['hookahbg']}
        style={StyleSheet.applyWidth(
          { flex: 1, height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', alignSelf: 'auto', flex: 1, height: '50%' },
            dimensions.width
          )}
        >
          <LinearGradient
            startX={0}
            color1={'rgba(0, 0, 0, 0)'}
            color2={palettes.App.color_2}
            color3={palettes.App.color_1}
            endX={0}
            endY={0}
            startY={100}
            style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
          >
            {/* heading */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.branding.primary,
                  fontFamily: 'Aladin_400Regular',
                  fontSize: 32,
                  marginTop: 20,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {'Welcome to Hookaah\n'}
            </Text>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 15,
                  paddingBottom: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              <CircleImage
                size={75}
                source={{
                  uri: `https://hookaah-bot.azurewebsites.net/photo?name=${Constants['UserImagePath']}`,
                }}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', justifyContent: 'center' },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.Brand['Strong Inverse'],
                      fontFamily: 'Aladin_400Regular',
                      fontSize: 26,
                    }
                  ),
                  dimensions.width
                )}
              >
                {Constants['TgUsername']}
                {'\n'}
              </Text>
            </View>
          </LinearGradient>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', height: '50%', justifyContent: 'flex-end' },
            dimensions.width
          )}
        >
          <LinearGradient
            startX={0}
            color1={palettes.App.Theme_background_2}
            color2={palettes.App.color_1}
            color3={'rgba(0, 0, 0, 0)'}
            endX={0}
            endY={0}
            startY={100}
            style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
          >
            {/* description */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.Brand['Strong Inverse'],
                  fontFamily: 'Aladin_400Regular',
                  fontSize: 20,
                  marginBottom: 20,
                  marginTop: 5,
                  opacity: 1,
                  paddingLeft: 40,
                  paddingRight: 40,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {
                'Join the ultimate GameFi experience where your tapping skills can earn you real rewards!'
              }
            </Text>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-start',
                  alignSelf: 'center',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              <Checkbox
                onCheck={() => {
                  const checkboxValue = undefined;
                  try {
                    setCheckboxValue(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onPress={newCheckboxValue => {
                  const checkboxValue = newCheckboxValue;
                  try {
                    setCheckboxValue(newCheckboxValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onUncheck={() => {
                  const checkboxValue = undefined;
                  try {
                    setCheckboxValue(false);
                    console.log(parseBoolean(checkboxValue));
                  } catch (err) {
                    console.error(err);
                  }
                }}
                status={checkboxValue}
                style={StyleSheet.applyWidth(
                  { marginRight: 10 },
                  dimensions.width
                )}
              />
              {/* Disclaimer */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: 1,
                    justifyContent: 'flex-end',
                    marginBottom: 15,
                    opacity: 1,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.Brand['Strong Inverse'],
                      fontFamily: 'Aladin_400Regular',
                      fontSize: 16,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'By signing up or logging in, i accept the apps '}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {/* Terms of service */}
                  <Touchable>
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.branding.primary,
                          fontFamily: 'Aladin_400Regular',
                          fontSize: 16,
                          opacity: 1,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {'Terms of service'}
                    </Text>
                  </Touchable>
                  {/* and */}
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.Brand.Surface,
                        fontFamily: 'Aladin_400Regular',
                        fontSize: 16,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {' and '}
                  </Text>
                  {/* Privacy policy */}
                  <Touchable>
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.branding.primary,
                          fontFamily: 'Aladin_400Regular',
                          fontSize: 16,
                          lineHeight: 20,
                          opacity: 1,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {'Privacy policy'}
                    </Text>
                  </Touchable>
                </View>
              </View>
            </View>
            {/* Get Started */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  console.log('Get Started ON_PRESS Start');
                  let error = null;
                  try {
                    console.log('Start ON_PRESS:0 IF');
                    if (checkboxValue) {
                      const POST_USER = (
                        await uSERREGISTERUSERPOST.mutateAsync({
                          networkId: Constants['Network_id'],
                          profileImagePath: Constants['UserImagePath'],
                          tgId: Constants['TgId'],
                          tgUsername: Constants['TgUsername'],
                        })
                      )?.json;
                      navigation.navigate('MainScreen', { newUser: true });
                    } else {
                    }
                    console.log('Complete ON_PRESS:0 IF');
                  } catch (err) {
                    console.error(err);
                    error = err.message ?? err;
                  }
                  console.log(
                    'Get Started ON_PRESS Complete',
                    error ? { error } : 'no error'
                  );
                };
                handler();
              }}
              title={'Get Started'}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.branding.primary,
                  borderRadius: 24,
                  fontFamily: 'Inter_500Medium',
                  fontSize: 16,
                  height: 54,
                  marginBottom: 30,
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 30,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            />
          </LinearGradient>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(RegisterScreen);
