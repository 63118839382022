const hookahModalState = setGlobalVariableValue => {
  // var modalHookahState = Variables.ModalHookahState;
  console.log('function Delay of 5 seconds called');

  console.log('Delay of 5 seconds start');
  return new Promise(resolve => {
    setTimeout(() => {
      console.log('Delay of 5 seconds completed.');
      setGlobalVariableValue({ key: 'ModalHookahState', value: false });
      resolve('Delay of 5 seconds completed.');
    }, 2000); // Delay of 5000 milliseconds (5 seconds)
  });
};

export default hookahModalState;
