import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const gETHOOKAHLISTGET = async (
  Constants,
  { userId },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['userId'] = userId !== undefined ? renderParam(userId) : '';
  const url = `https://hookaah-app-api.azurewebsites.net/api/User/user-hookahs${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['Auth_Token'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGETHOOKAHLISTGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['MARKETS', args],
    () => gETHOOKAHLISTGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGETHOOKAHLISTGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGETHOOKAHLISTGET(
    { userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGETHOOKAHLIST: refetch });
};

export const gETMYSTRYBOXGET = async (Constants, _args, handlers = {}) => {
  const url = `https://hookaah-app-api.azurewebsites.net/api/HookahMystryboxes`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGETMYSTRYBOXGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['MARKETS', args],
    () => gETMYSTRYBOXGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGETMYSTRYBOXGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGETMYSTRYBOXGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGETMYSTRYBOX: refetch });
};

export const gETNETWORKGET = async (
  Constants,
  { referralId, userId },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['userId'] = userId !== undefined ? renderParam(userId) : '';
  paramsDict['referralId'] =
    referralId !== undefined ? renderParam(referralId) : '';
  const url = `https://hookaah-app-api.azurewebsites.net/api/User/user-network${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['Auth_Token'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGETNETWORKGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Users', args],
    () => gETNETWORKGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGETNETWORKGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  referralId,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGETNETWORKGET(
    { referralId, userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGETNETWORK: refetch });
};

export const gETUSERTASKSGET = async (Constants, { userId }, handlers = {}) => {
  const paramsDict = {};
  paramsDict['userId'] = userId !== undefined ? renderParam(userId) : '';
  const url = `https://hookaah-app-api.azurewebsites.net/api/User/task${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['Auth_Token'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGETUSERTASKSGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['TASKS', args],
    () => gETUSERTASKSGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGETUSERTASKSGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGETUSERTASKSGET(
    { userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGETUSERTASKS: refetch });
};

export const lOGINUSERPOST = async (
  Constants,
  { tgId, tgUsername },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['TgId'] = tgId !== undefined ? renderParam(tgId) : '';
  paramsDict['TgUsername'] =
    tgUsername !== undefined ? renderParam(tgUsername) : '';
  const url = `https://hookaah-app-api.azurewebsites.net/api/Auth/LOGIN${renderQueryString(
    paramsDict
  )}`;
  const options = {
    body: JSON.stringify({ key: 'value' }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useLOGINUSERPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => lOGINUSERPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const FetchLOGINUSERPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  tgId,
  tgUsername,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useLOGINUSERPOST(
    { tgId, tgUsername },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLOGINUSER: refetch });
};

export const pOSTSCOREPOST = async (
  Constants,
  { scores, userId },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['userId'] = userId !== undefined ? renderParam(userId) : '';
  paramsDict['scores'] = scores !== undefined ? renderParam(scores) : '';
  const url = `https://hookaah-app-api.azurewebsites.net/api/User/add-score${renderQueryString(
    paramsDict
  )}`;
  const options = {
    body: JSON.stringify({ key: 'value' }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['Auth_Token'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePOSTSCOREPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => pOSTSCOREPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const FetchPOSTSCOREPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  scores,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = usePOSTSCOREPOST(
    { scores, userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPOSTSCORE: refetch });
};

export const pOSTTASKFINISHPOST = async (
  Constants,
  { taskId, userId },
  handlers = {}
) => {
  const url = `https://hookaah-app-api.azurewebsites.net/api/User/task/finish`;
  const options = {
    body: JSON.stringify({
      id: 0,
      userId: userId,
      taskId: taskId,
      claimed: 'string',
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['Auth_Token'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePOSTTASKFINISHPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      pOSTTASKFINISHPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('TASK', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('TASK');
        queryClient.invalidateQueries('TASKS');
      },
    }
  );
};

export const FetchPOSTTASKFINISHPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  taskId,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = usePOSTTASKFINISHPOST(
    { taskId, userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPOSTTASKFINISH: refetch });
};

export const pURSHASEHOOKAHPOST = async (
  Constants,
  { hookahId, userId },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['UserId'] = userId !== undefined ? renderParam(userId) : '';
  paramsDict['HookahId'] = hookahId !== undefined ? renderParam(hookahId) : '';
  const url = `https://hookaah-app-api.azurewebsites.net/api/User/purchase-hookah${renderQueryString(
    paramsDict
  )}`;
  const options = {
    body: JSON.stringify({ key: 'value' }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['Auth_Token'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePURSHASEHOOKAHPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      pURSHASEHOOKAHPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('MARKET', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('MARKET');
        queryClient.invalidateQueries('MARKETS');
      },
    }
  );
};

export const FetchPURSHASEHOOKAHPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  hookahId,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = usePURSHASEHOOKAHPOST(
    { hookahId, userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPURSHASEHOOKAH: refetch });
};

export const pURSHASEMYSTRYBOXPOST = async (
  Constants,
  { mystryboxId, userId },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['userId'] = userId !== undefined ? renderParam(userId) : '';
  paramsDict['mystryboxId'] =
    mystryboxId !== undefined ? renderParam(mystryboxId) : '';
  const url = `https://hookaah-app-api.azurewebsites.net/api/User/purchase-mystrybox${renderQueryString(
    paramsDict
  )}`;
  const options = {
    body: JSON.stringify({ key: 'value' }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['Auth_Token'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePURSHASEMYSTRYBOXPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      pURSHASEMYSTRYBOXPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('MARKET', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('MARKET');
        queryClient.invalidateQueries('MARKETS');
      },
    }
  );
};

export const FetchPURSHASEMYSTRYBOXPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  mystryboxId,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = usePURSHASEMYSTRYBOXPOST(
    { mystryboxId, userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPURSHASEMYSTRYBOX: refetch });
};

export const pUTTASKCLAIMEDPUT = async (
  Constants,
  { taskId, userId },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['UserId'] = userId !== undefined ? renderParam(userId) : '';
  paramsDict['TaskId'] = taskId !== undefined ? renderParam(taskId) : '';
  const url = `https://hookaah-app-api.azurewebsites.net/api/User/task/claim${renderQueryString(
    paramsDict
  )}`;
  const options = {
    body: JSON.stringify({ key: 'value' }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['Auth_Token'],
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePUTTASKCLAIMEDPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => pUTTASKCLAIMEDPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('TASK', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('TASK');
        queryClient.invalidateQueries('TASKS');
      },
    }
  );
};

export const rEGISTERUSERPOST = async (
  Constants,
  { networkId, profileImagePath, tgId, tgUsername },
  handlers = {}
) => {
  const url = `https://hookaah-app-api.azurewebsites.net/api/Auth/register`;
  const options = {
    body: JSON.stringify({
      id: 0,
      tgId: tgId,
      tgUsername: tgUsername,
      profileImagePath: profileImagePath,
      createdAt: '2024-07-24T13:29:17.367Z',
      referralId: 'null',
      networkId: networkId,
      walletAddress: 'null',
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useREGISTERUSERPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => rEGISTERUSERPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const FetchREGISTERUSERPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  networkId,
  profileImagePath,
  tgId,
  tgUsername,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useREGISTERUSERPOST(
    { networkId, profileImagePath, tgId, tgUsername },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchREGISTERUSER: refetch });
};

export const sELECTHOOKAHPUT = async (
  Constants,
  { hookahId, userId },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['userId'] = userId !== undefined ? renderParam(userId) : '';
  paramsDict['hookahId'] = hookahId !== undefined ? renderParam(hookahId) : '';
  const url = `https://hookaah-app-api.azurewebsites.net/api/User/select-hookah${renderQueryString(
    paramsDict
  )}`;
  const options = {
    body: JSON.stringify({ key: 'value' }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['Auth_Token'],
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSELECTHOOKAHPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => sELECTHOOKAHPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('MARKET', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('MARKET');
        queryClient.invalidateQueries('MARKETS');
      },
    }
  );
};

export const uSERCHECKINPOST = async (Constants, { userId }, handlers = {}) => {
  const paramsDict = {};
  paramsDict['userId'] = userId !== undefined ? renderParam(userId) : '';
  const url = `https://hookaah-app-api.azurewebsites.net/api/Auth/checkin${renderQueryString(
    paramsDict
  )}`;
  const options = {
    body: JSON.stringify({ key: 'value' }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['Auth_Token'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUSERCHECKINPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => uSERCHECKINPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const FetchUSERCHECKINPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUSERCHECKINPOST(
    { userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUSERCHECKIN: refetch });
};
