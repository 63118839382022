const getQueryTgUsername = () => {
  const searchParams = new URLSearchParams(location.search);

  const TgUsername = searchParams.get('TgUsername');

  if (TgUsername) {
    return TgUsername;
  } else {
    return 'No value';
  }
};

export default getQueryTgUsername;
