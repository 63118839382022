import { Alert } from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';

// Define and export your function as a named export here.
export const copy = (referral_id, url) => {
  // Copy text to clipboard
  var text = url + referral_id;
  Clipboard.setString(text);
  // Show alert or any other feedback
  Alert.alert('Copied to clipboard', text);
};
