import React from 'react';
import {
  Button,
  CircleImage,
  Icon,
  LinearGradient,
  LinearProgress,
  ScreenContainer,
  Touchable,
  ZStack,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Modal,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as USERApi from '../apis/USERApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Animations from '../custom-files/Animations';
import * as CustomCode from '../custom-files/CustomCode';
import * as MultiTouch from '../custom-files/MultiTouch';
import ADD_SCORE from '../global-functions/ADD_SCORE';
import LEVEL_SCORE from '../global-functions/LEVEL_SCORE';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import hapticFeedbackUtil from '../utils/hapticFeedback';
import useWindowDimensions from '../utils/useWindowDimensions';

const MainScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const uSERPOSTSCOREPOST = USERApi.usePOSTSCOREPOST();
  const uSERUSERCHECKINPOST = USERApi.useUSERCHECKINPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'newUserModal',
        value: props.route?.params?.newUser ?? false,
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (isFocused) {
          return;
        }
        const USER_SCORE = (
          await uSERPOSTSCOREPOST.mutateAsync({
            scores: Constants['TAP_SCORE'],
            userId: Constants['UserId'],
          })
        )?.json;
        setGlobalVariableValue({
          key: 'TAP_SCORE',
          value: 0,
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasLeftSafeArea={false}
      hasRightSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.Theme_background },
        dimensions.width
      )}
    >
      <USERApi.FetchLOGINUSERPOST
        handlers={{
          on2xx: fetchData => {
            const handler = async () => {
              try {
                setGlobalVariableValue({
                  key: 'WelcomeModal',
                  value: false,
                });
                setGlobalVariableValue({
                  key: 'Auth_Token',
                  value: fetchData?.json?.accessToken,
                });
                setGlobalVariableValue({
                  key: 'FETCH_SCORE',
                  value: fetchData?.json?.user_profile?.score,
                });
                setGlobalVariableValue({
                  key: 'EARN_PER_TAP',
                  value: fetchData?.json?.hookah?.earnPerTape,
                });
                setGlobalVariableValue({
                  key: 'LEVEL',
                  value: fetchData?.json?.user_profile?.level,
                });
                setGlobalVariableValue({
                  key: 'UserId',
                  value: fetchData?.json?.user?.id,
                });
                setGlobalVariableValue({
                  key: 'Referral_id',
                  value: fetchData?.json?.user?.referralId,
                });
                setGlobalVariableValue({
                  key: 'Network_id',
                  value: fetchData?.json?.user?.networkId,
                });
                setGlobalVariableValue({
                  key: 'Hookah_image',
                  value: fetchData?.json?.hookah?.imagePath,
                });
                LEVEL_SCORE(Variables, setGlobalVariableValue);
                const result = (
                  await uSERUSERCHECKINPOST.mutateAsync({
                    userId: fetchData?.json?.user?.id,
                  })
                )?.json;
                if (result?.earned > 0) {
                  setGlobalVariableValue({
                    key: 'checkin_reward',
                    value: result?.earned,
                  });
                  setGlobalVariableValue({
                    key: 'ModalCheckin',
                    value: true,
                  });
                } else {
                  setGlobalVariableValue({
                    key: 'ModalCheckin',
                    value: false,
                  });
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          },
          on401: fetchData => {
            try {
              navigation.navigate('WelcomeScreen');
            } catch (err) {
              console.error(err);
            }
          },
          on404: fetchData => {
            try {
              setGlobalVariableValue({
                key: 'WelcomeModal',
                value: false,
              });
              navigation.navigate('RegisterScreen');
            } catch (err) {
              console.error(err);
            }
          },
        }}
        tgId={Constants['TgId']}
        tgUsername={Constants['TgUsername']}
      >
        {({ loading, error, data, refetchLOGINUSER }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* User_Profile */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App.Theme_background,
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 15,
                      paddingBottom: 5,
                      paddingTop: 5,
                    },
                    dimensions.width
                  )}
                >
                  <CircleImage
                    size={75}
                    source={{
                      uri: `https://hookaah-bot.azurewebsites.net/photo?name=${fetchData?.user?.profileImagePath}`,
                    }}
                  />
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 5, paddingTop: 5 },
                    dimensions.width
                  )}
                >
                  {/* user_name */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    selectionColor={theme.colors.branding.primary}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App.Theme_text_1,
                          fontFamily: 'Aladin_400Regular',
                          fontSize: 23,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {fetchData?.user?.tgUsername}
                  </Text>
                </View>
              </View>
              {/* Dashboard */}
              <View>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      marginLeft: 20,
                      marginRight: 20,
                      paddingBottom: 5,
                      paddingTop: 5,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { marginRight: 10 },
                      dimensions.width
                    )}
                  >
                    <CircleImage size={32} source={Images['logo3']} />
                  </View>
                  {/* coins_balance */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: theme.colors.branding.primary,
                          fontFamily: 'Aladin_400Regular',
                          fontSize: 26,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {ADD_SCORE(
                      Constants['FETCH_SCORE'],
                      parseInt(Constants['TAP_SCORE'], 10)
                    )}
                  </Text>
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginLeft: 20,
                      marginRight: 20,
                    },
                    dimensions.width
                  )}
                >
                  {/* View 4 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'column' },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors.branding.primary}
                        name={'MaterialCommunityIcons/hand-pointing-up'}
                        size={18}
                        style={StyleSheet.applyWidth(
                          { marginRight: 5 },
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App.Theme_text_1,
                              fontFamily: 'Aladin_400Regular',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Earn/Tape'}
                      </Text>
                    </View>

                    <View>
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: theme.colors.branding.primary,
                              fontFamily: 'Aladin_400Regular',
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'+'}
                        {fetchData?.hookah?.earnPerTape}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center' },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors.branding.primary}
                        name={'MaterialCommunityIcons/arrow-up-bold'}
                        size={16}
                        style={StyleSheet.applyWidth(
                          { marginRight: 5 },
                          dimensions.width
                        )}
                      />
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App.Theme_text_1,
                              fontFamily: 'Aladin_400Regular',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'LEVEL'}
                      </Text>
                    </View>
                    {/* View 2 */}
                    <View>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: theme.colors.branding.primary,
                              fontFamily: 'Aladin_400Regular',
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {Constants['LEVEL']}
                      </Text>
                    </View>
                  </View>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center' },
                      dimensions.width
                    )}
                  >
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={theme.colors.branding.primary}
                        name={'MaterialCommunityIcons/timer-sand'}
                        size={16}
                        style={StyleSheet.applyWidth(
                          { marginRight: 5 },
                          dimensions.width
                        )}
                      />
                      {/* Text 3 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App.Theme_text_1,
                              fontFamily: 'Aladin_400Regular',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Earn/Hour'}
                      </Text>
                    </View>
                    {/* View 3 */}
                    <View>
                      {/* Text 3 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: theme.colors.branding.primary,
                              fontFamily: 'Aladin_400Regular',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'+'}
                        {fetchData?.hookah?.earnPerHour}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* View 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      marginLeft: 20,
                      marginRight: 20,
                    },
                    dimensions.width
                  )}
                >
                  <LinearProgress
                    animationDuration={500}
                    color={theme.colors.branding.primary}
                    indeterminate={false}
                    isAnimated={true}
                    lineCap={'round'}
                    showTrack={true}
                    thickness={10}
                    trackColor={theme.colors.border.brand}
                    trackLineCap={'round'}
                    style={StyleSheet.applyWidth(
                      {
                        height: 20,
                        marginBottom: 10,
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 10,
                      },
                      dimensions.width
                    )}
                    value={Constants['SCORE_PERCENTAGE']}
                  />
                </View>
              </View>
              {/* Hookah_Play */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flex: 1,
                    height: '50%',
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Custom Color_6'],
                        fontFamily: 'Aladin_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Tap with your fingres'}
                </Text>
                <Utils.CustomCodeErrorBoundary>
                  <MultiTouch.MultiTouchComponent />
                </Utils.CustomCodeErrorBoundary>
              </View>
            </>
          );
        }}
      </USERApi.FetchLOGINUSERPOST>
      {/* BottomNavigation_Bar */}
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            height: '10%',
            justifyContent: 'space-between',
            position: 'relative',
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: 'rgb(21, 20, 20)',
              borderBottomLeftRadius: 30,
              borderBottomRightRadius: 30,
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginBottom: 25,
              marginLeft: 10,
              marginRight: 10,
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('MainScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'MaterialCommunityIcons/bank'}
              />
            </View>
          </Touchable>
          {/* Touchable 2 */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('TasksScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'MaterialCommunityIcons/clipboard-check-multiple-outline'}
              />
            </View>
          </Touchable>
          {/* Touchable 3 */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('MarketScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'SimpleLineIcons/social-dropbox'}
              />
            </View>
          </Touchable>
          {/* Touchable 4 */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('UserScreen', { var: 1 });
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'Feather/user'}
              />
            </View>
          </Touchable>
        </View>
      </View>
      {/* mining_modal */}
      <>
        {!Constants['ModalCheckin'] ? null : (
          <Modal
            animationType={'none'}
            supportedOrientations={['portrait', 'landscape']}
            transparent={true}
          >
            <View
              style={StyleSheet.applyWidth(
                { backgroundColor: palettes.App.Theme_background, flex: 1 },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', padding: 25 },
                  dimensions.width
                )}
              >
                <CircleImage size={100} source={Images['congratsgif1']} />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'Aladin_400Regular',
                        fontSize: 32,
                        margin: 25,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Congrat !'}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'Aladin_400Regular',
                        fontSize: 22,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'Your hookah mining reward per hour is arrived to you, your hookah generate for you the amount of\n\n'
                  }
                </Text>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <CircleImage
                  size={60}
                  source={Images['logo1']}
                  style={StyleSheet.applyWidth(
                    { marginBottom: 10, marginTop: 10 },
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'Montserrat_700Bold',
                        fontSize: 32,
                        margin: 10,
                        textAlign: 'right',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {Constants['checkin_reward']}
                  {' H\n'}
                </Text>
              </View>
              {/* View 3 */}
              <View>
                {/* Button 3 */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'ModalCheckin',
                        value: false,
                      });
                      navigation.navigate('MainScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderColor: theme.colors.background.brand,
                        borderWidth: 2.5,
                        color: theme.colors.background.brand,
                        fontFamily: 'Montserrat_600SemiBold',
                        fontSize: 22,
                        margin: 25,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Close'}
                />
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* loading_data_modal */}
      <>
        {!Constants['WelcomeModal'] ? null : (
          <Modal
            animationType={'none'}
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
          >
            <ImageBackground
              resizeMode={'cover'}
              source={Images['hookahbg']}
              style={StyleSheet.applyWidth(
                { flex: 1, height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'auto',
                    flex: 1,
                    height: '100%',
                  },
                  dimensions.width
                )}
              >
                <LinearGradient
                  startX={0}
                  color1={'rgba(0, 0, 0, 0)'}
                  color2={palettes.App.color_1}
                  color3={palettes.App.Theme_background}
                  endX={0}
                  endY={0}
                  startY={200}
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', height: '50%', width: '100%' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center' },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors.branding.primary,
                            fontFamily: 'Aladin_400Regular',
                            fontSize: 42,
                            marginTop: 30,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Hookah Token'}
                    </Text>
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors.background.brand,
                            fontFamily: 'Aladin_400Regular',
                            fontSize: 30,
                            marginTop: 10,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'will be lunched on'}
                    </Text>
                    <Image
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      resizeMode={'contain'}
                      source={Images['ton']}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { height: 150, width: 250 }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, width: '100%' },
                      dimensions.width
                    )}
                  >
                    <Image
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      resizeMode={'cover'}
                      source={Images['loader']}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          { width: '100%' }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </LinearGradient>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flex: 1,
                      justifyContent: 'flex-end',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <LinearGradient
                    startX={0}
                    color1={palettes.App.Theme_background_2}
                    color2={palettes.App.color_1}
                    color3={'rgba(0, 0, 0, 0)'}
                    endX={0}
                    endY={0}
                    startY={100}
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        height: '60%',
                        justifyContent: 'flex-end',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View>
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: theme.colors.background.brand,
                              fontFamily: 'Aladin_400Regular',
                              fontSize: 18,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Stay tuned\nMore info in official channels'}
                      </Text>
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          justifyContent: 'space-evenly',
                          margin: 20,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <CircleImage size={42} source={Images['x']} />
                      {/* Circle Image 2 */}
                      <CircleImage size={42} source={Images['telegrambw']} />
                      {/* Circle Image 3 */}
                      <CircleImage size={42} source={Images['instabw']} />
                    </View>

                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: theme.colors.background.brand,
                            fontFamily: 'Aladin_400Regular',
                            fontSize: 16,
                            marginBottom: 20,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Copyright Hookah © 2024'}
                    </Text>
                  </LinearGradient>
                </View>
              </View>
            </ImageBackground>
          </Modal>
        )}
      </>
      {/* new_user_modal */}
      <>
        {!Constants['newUserModal'] ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            animationType={'fade'}
            transparent={true}
            visible={props.route?.params?.newUser ?? false}
          >
            <View
              style={StyleSheet.applyWidth(
                { backgroundColor: palettes.App.Theme_background, flex: 1 },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', padding: 20 },
                  dimensions.width
                )}
              >
                <CircleImage size={120} source={Images.welcomegif} />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'Aladin_400Regular',
                        fontSize: 26,
                        margin: 20,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'To our Hookah ! 🎉'}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'Aladin_400Regular',
                        fontSize: 20,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    "We're thrilled to have you on board. As a token of our appreciation for joining our community, \n\nWe've credited your account with 500 H. \n\nEnjoy your rewards and dive into the ultimate hookah experience with us!\n\nHappy smoking! 💨\n"
                  }
                </Text>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'Aladin_400Regular',
                        fontSize: 32,
                        margin: 10,
                        textAlign: 'right',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'500 H\n'}
                </Text>
              </View>
              {/* View 3 */}
              <View>
                {/* Button 3 */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'newUserModal',
                        value: false,
                      });
                      navigation.navigate('MainScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderColor: theme.colors.background.brand,
                        borderWidth: 2.5,
                        color: theme.colors.background.brand,
                        fontFamily: 'Montserrat_600SemiBold',
                        fontSize: 22,
                        margin: 25,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Close'}
                />
              </View>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(MainScreen);
