const getReferralId = () => {
  const searchParams = new URLSearchParams(location.search);
  const ReferrerId = searchParams.get('referrerId');

  if (ReferrerId) {
    return ReferrerId;
  } else {
    return 'No value';
  }
};

export default getReferralId;
