const getQueryTgId = () => {
  const searchParams = new URLSearchParams(location.search);
  const TgId = searchParams.get('TgId');

  if (TgId) {
    return TgId;
  } else {
    return;
  }
};

export default getQueryTgId;
