import React, { useState, useRef, useEffect } from 'react';
import {
  View,
  Image,
  Text,
  useWindowDimensions,
  Animated,
  Vibration,
} from 'react-native';
import {
  GestureHandlerRootView,
  TapGestureHandler,
  State,
} from 'react-native-gesture-handler';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Images from '../config/Images';
import * as Animations from '../custom-files/Animations';
import ADD_SCORE from '../global-functions/ADD_SCORE';
import LEVEL_SCORE from '../global-functions/LEVEL_SCORE';
import * as Utils from '../utils';
import * as GlobalVariables from '../config/GlobalVariableContext';
import { ZStack } from '@draftbit/ui';
import { LinearGradient } from '@draftbit/ui';
import * as StyleSheet from '../utils/StyleSheet';
import * as USERApi from '../apis/USERApi.js';

export const MultiTouchComponent = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const scale = useRef(new Animated.Value(1)).current;
  const timeoutRef = useRef(null);
  const uSERPOSTSCOREPOST = USERApi.usePOSTSCOREPOST();
  const handleMultiTouch = ({ nativeEvent }) => {
    if (nativeEvent.state === State.ACTIVE) {
      // console.log('Three-finger tap detected');
      try {
        LEVEL_SCORE(Variables, setGlobalVariableValue);
        setGlobalVariableValue({
          key: 'TAP_SCORE',
          value: ADD_SCORE(Constants['TAP_SCORE'], Constants['EARN_PER_TAP']),
        });

        // console.log(Constants['TAP_SCORE']);
      } catch (err) {
        console.error(err);
      }

      // Trigger vibration
      // Trigger a short vibration
      Vibration.vibrate(50);

      Animated.sequence([
        Animated.timing(scale, {
          toValue: 0.9,
          duration: 20,
          useNativeDriver: true,
        }),
        Animated.timing(scale, {
          toValue: 1,
          duration: 20,
          useNativeDriver: true,
        }),
      ]).start();

      // Reset the no-touch timeout
      resetTimeout();
    }
  };

  const resetTimeout = () => {
    // Clear the existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout for 5 seconds (5000 ms)
    timeoutRef.current = setTimeout(() => {
      const handler = async () => {
        try {
          const USER_SCORE = (
            await uSERPOSTSCOREPOST.mutateAsync({
              scores: Constants['TAP_SCORE'],
              userId: Constants['UserId'],
            })
          )?.json;

          setGlobalVariableValue({
            key: 'TAP_SCORE',
            value: 0,
          });
          setGlobalVariableValue({
            key: 'FETCH_SCORE',
            value: USER_SCORE?.score,
          });

          console.log('TRIGGER');
        } catch (err) {
          console.error(err);
        }
      };

      handler();
      // console.log('No touch detected after the last interaction.');
    }, 2000); // Adjust the time as needed
  };

  // Clean up the timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const animatedStyle = {
    transform: [{ scale }],
  };

  return (
    <GestureHandlerRootView style={styles.rootContainer}>
      <TapGestureHandler
        onHandlerStateChange={handleMultiTouch}
        maxPointers={5}
      >
        <View style={styles.touchableArea}>
          <ZStack
            reversed={false}
            {...GlobalStyles.ZStackStyles(theme)['Z Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ZStackStyles(theme)['Z Stack'].style,
                styles.zstack
              ),
              dimensions.width
            )}
          >
            {/* View 4 */}
            <View
              style={StyleSheet.applyWidth(
                { height: '100%', padding: 0, width: '100%' },
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'cover'}
                source={Images['neobg']}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: '100%', width: '100%' }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { height: '100%', padding: 0, width: '100%' },
                dimensions.width
              )}
            >
              <LinearGradient
                startX={0}
                color1={'rgba(0, 0, 0, 0)'}
                color2={palettes.black_color['Custom Color_2']}
                color3={palettes.black_color['Custom Color']}
                endX={0}
                endY={0}
                startY={100}
                style={StyleSheet.applyWidth(
                  {
                    flex: 1,
                    justifyContent: 'flex-start',
                    width: '100%',
                  },
                  dimensions.width
                )}
              />
              {/* Linear Gradient 2 */}
              <LinearGradient
                startX={0}
                color1={palettes.App.Theme_background}
                color2={palettes.App.color_2}
                endX={0}
                endY={0}
                startY={100}
                style={StyleSheet.applyWidth(
                  {
                    flex: 1,
                    height: '50%',
                    justifyContent: 'flex-start',
                    width: '100%',
                  },
                  dimensions.width
                )}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                { height: '100%', padding: 40, width: '100%' },
                dimensions.width
              )}
            >
              <Animated.Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'center'}
                source={`https://hookaah-bot.azurewebsites.net/photo?name=${Constants['Hookah_image']}`}
                style={[
                  StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: '100%', width: '100%' }
                    ),
                    dimensions.width
                  ),
                  animatedStyle,
                ]}
              />
            </View>

            <View style={styles.innerView}>
              <Utils.CustomCodeErrorBoundary>
                <Animations.Animations />
              </Utils.CustomCodeErrorBoundary>
            </View>
          </ZStack>
        </View>
      </TapGestureHandler>
    </GestureHandlerRootView>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    height: '100%',
    width: '100%',
  },
  touchableArea: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  zstack: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  innerView: {
    height: '100%',
    padding: 25,
    width: '100%',
  },
  image: {
    height: '100%',
    width: '100%',
  },
  text: {
    marginTop: 5,
    fontSize: 12,
    color: 'white',
  },
});
