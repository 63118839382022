import React, { useState } from 'react';
import { View, StyleSheet, Animated, Text, Image } from 'react-native';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';

// Component for animating text
const AnimatedText = ({ x, y, position, opacity, text }) => (
  <Animated.Text
    style={[
      styles.animatedText,
      {
        transform: [{ translateY: position }],
        opacity,
        left: x - 10, // Adjusting position to center the text
        top: y - 10, // Adjusting position to center the text
      },
    ]}
  >
    {text}
  </Animated.Text>
);

// Component for animating image with color tint animation
const AnimatedImage = ({ x, y, position, opacity, tintColor, imageSource }) => (
  <Animated.Image
    source={imageSource}
    style={[
      styles.animatedImage,
      {
        transform: [{ translateY: position }],
        opacity,
        tintColor, // Apply the interpolated tint color
        left: x,
        top: y,
      },
    ]}
  />
);

export const Animations = () => {
  const [animations, setAnimations] = useState([]);
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  }); // State to store container dimensions (width, height)

  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const EARN_PER_TAP = Variables.EARN_PER_TAP;

  const handlePress = event => {
    const { locationX, locationY } = event.nativeEvent;
    console.log({ locationX, locationY });

    // Get container width and height
    const { width, height } = containerDimensions;

    // Calculate random x and y positions for the image within the container's bounds
    const randomX = Math.random() * (width - 50); // Subtracting image width (50) to keep the image inside the container
    const randomY = Math.random() * (height - 50); // Subtracting image height (50) to keep the image inside the container

    // Create an animation object for both text and image
    const newTextAnimation = {
      key: Math.random().toString(),
      type: 'text',
      position: new Animated.Value(0),
      opacity: new Animated.Value(1),
      x: locationX, // This still depends on the user's tap
      y: locationY, // This still depends on the user's tap
      text: `+${EARN_PER_TAP}`, // Using the value of EARN_PER_TAP
    };

    // Create an animated value for color tinting
    const colorAnimation = new Animated.Value(0);

    // Interpolate the tint color between neon pink and blue
    const tintColor = colorAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: ['#FF6EC7', '#04D9FF'], // Neon pink to blue
    });

    // Create an image animation with random X and Y positions
    const newImageAnimation = {
      key: Math.random().toString(),
      type: 'image',
      position: new Animated.Value(0),
      opacity: new Animated.Value(1),
      tintColor, // Apply the interpolated color
      x: randomX, // Randomized X position
      y: randomY, // Randomized Y position
      imageSource: Images['smokns'], // Use the image for animation
    };

    setAnimations(currentAnimations => [
      ...currentAnimations,
      newTextAnimation,
      newImageAnimation,
    ]);

    // Animate text (this follows the user's tap location)
    Animated.timing(newTextAnimation.position, {
      toValue: -250,
      duration: 1500,
      useNativeDriver: true,
    }).start();

    Animated.timing(newTextAnimation.opacity, {
      toValue: 0.25,
      duration: 1500,
      useNativeDriver: true,
    }).start(() => {
      setAnimations(currentAnimations =>
        currentAnimations.filter(anim => anim.key !== newTextAnimation.key)
      );
    });

    // Animate image (this starts at a random position)
    Animated.timing(newImageAnimation.position, {
      toValue: -250,
      duration: 1500,
      useNativeDriver: true,
    }).start();

    Animated.timing(newImageAnimation.opacity, {
      toValue: 0.25,
      duration: 1500,
      useNativeDriver: true,
    }).start(() => {
      setAnimations(currentAnimations =>
        currentAnimations.filter(anim => anim.key !== newImageAnimation.key)
      );
    });

    // Animate the color between neon pink and blue
    Animated.loop(
      Animated.timing(colorAnimation, {
        toValue: 1,
        duration: 1000, // Time for switching colors
        useNativeDriver: false,
      })
    ).start();
  };

  return (
    <View
      style={styles.container}
      onLayout={event => {
        const { width, height } = event.nativeEvent.layout; // Get the width and height of the container
        setContainerDimensions({ width, height }); // Store it in state
      }}
      onStartShouldSetResponder={() => true}
      onResponderRelease={handlePress}
    >
      {animations.map(anim =>
        anim.type === 'text' ? (
          <AnimatedText
            key={anim.key}
            x={anim.x}
            y={anim.y}
            position={anim.position}
            opacity={anim.opacity}
            text={anim.text}
          />
        ) : (
          <AnimatedImage
            key={anim.key}
            x={anim.x}
            y={anim.y}
            position={anim.position}
            opacity={anim.opacity}
            tintColor={anim.tintColor} // Apply animated tint color
            imageSource={anim.imageSource}
          />
        )
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  animatedText: {
    position: 'absolute',
    fontSize: 42, // Adjust the font size
    color: '#04D9FF', // Specify the color
    fontFamily: 'Aladin',
  },
  animatedImage: {
    position: 'absolute',
    width: 50, // Set the desired width of the image
    height: 50, // Set the desired height of the image
  },
});
