import React, { createContext, useContext, useEffect, useState } from 'react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

const TonConnectContext = createContext();

export const TonConnectProvider = ({ children }) => {
  const [tonConnectUI, setTonConnectUI] = useState(null);

  useEffect(() => {
    const initTonConnect = async () => {
      const tonConnect = new TonConnectUIProvider({
        manifestUrl: 'https://hookah-bot.azurewebsites.net/manifest.json', // Replace with your manifest URL
      });
      setTonConnectUI(tonConnect);
    };

    initTonConnect();
  }, []);

  return (
    <TonConnectUIProvider manifestUrl="https://hookah-bot.azurewebsites.net/manifest.json">
      <TonConnectContext.Provider value={tonConnectUI}>
        {children}
      </TonConnectContext.Provider>
    </TonConnectUIProvider>
  );
};

export const useTonConnect = () => useContext(TonConnectContext);
