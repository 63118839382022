export default {
  hookahbg: require('../assets/images/HookahBg.png'),
  x: require('../assets/images/X.png'),
  neobg: require('../assets/images/NeoBg.jpg'),
  ton: require('../assets/images/Ton.png'),
  congratsgif1: require('../assets/images/CongratsGif1.gif'),
  welcomegif: require('../assets/images/WelcomeGif.gif'),
  logo1: require('../assets/images/Logo1.png'),
  logo3: require('../assets/images/Logo3.png'),
  instabw: require('../assets/images/InstaBw.png'),
  telegrambw: require('../assets/images/TelegramBw.png'),
  loader: require('../assets/images/Loader.gif'),
  smock: require('../assets/images/Smock.png'),
  smocknew: require('../assets/images/Smocknew.png'),
  smockn: require('../assets/images/Smockn.png'),
  smokns: require('../assets/images/Smokns.png'),
};
