import * as copy from '../custom-files/copy';

const copyTo = async (referral_id, url) => {
  try {
    await copy.copy(referral_id, url); // Call the imported copy function with the parameters
    console.log('Copy');
    // Any additional logic can be added here if needed
  } catch (error) {
    console.error('Error copying data:', error);
  }
  return;
};

export default copyTo;
