const LEVEL_SCORE = (Variables, setGlobalVariableValue) => {
  var TOTAL_SCORE = Variables.FETCH_SCORE + Variables.TAP_SCORE;

  if (TOTAL_SCORE < 1000) {
    var SCORE_PERCENTAGE = (TOTAL_SCORE / 1000) * 100;
    setGlobalVariableValue({
      key: 'SCORE_PERCENTAGE',
      value: SCORE_PERCENTAGE,
    });
    setGlobalVariableValue({ key: 'LEVEL', value: 1 });
    console.log(Variables.SCORE_PERCENTAGE);
    console.log(Variables.LEVEL);
    return;
  }
  if (TOTAL_SCORE > 1000 && TOTAL_SCORE < 5000) {
    var SCORE_PERCENTAGE = ((TOTAL_SCORE - 1000) / (5000 - 1000)) * 100;
    setGlobalVariableValue({
      key: 'SCORE_PERCENTAGE',
      value: SCORE_PERCENTAGE,
    });
    setGlobalVariableValue({ key: 'LEVEL', value: 2 });
    return;
  }
  if (TOTAL_SCORE > 5000 && TOTAL_SCORE < 10000) {
    var SCORE_PERCENTAGE = ((TOTAL_SCORE - 5000) / (10000 - 5000)) * 100;
    setGlobalVariableValue({
      key: 'SCORE_PERCENTAGE',
      value: SCORE_PERCENTAGE,
    });
    setGlobalVariableValue({ key: 'LEVEL', value: 3 });
    return;
  }
  if (TOTAL_SCORE > 10000 && TOTAL_SCORE < 20000) {
    var SCORE_PERCENTAGE = ((TOTAL_SCORE - 10000) / (20000 - 10000)) * 100;
    setGlobalVariableValue({
      key: 'SCORE_PERCENTAGE',
      value: SCORE_PERCENTAGE,
    });
    setGlobalVariableValue({ key: 'LEVEL', value: 4 });
    return;
  }
  if (TOTAL_SCORE > 20000 && TOTAL_SCORE < 50000) {
    var SCORE_PERCENTAGE = ((TOTAL_SCORE - 20000) / (50000 - 20000)) * 100;
    setGlobalVariableValue({
      key: 'SCORE_PERCENTAGE',
      value: SCORE_PERCENTAGE,
    });
    setGlobalVariableValue({ key: 'LEVEL', value: 5 });
    return;
  }
  if (TOTAL_SCORE > 50000 && TOTAL_SCORE < 100000) {
    var SCORE_PERCENTAGE = ((TOTAL_SCORE - 50000) / (100000 - 50000)) * 100;
    setGlobalVariableValue({
      key: 'SCORE_PERCENTAGE',
      value: SCORE_PERCENTAGE,
    });
    setGlobalVariableValue({ key: 'LEVEL', value: 6 });
    return;
  }
  if (TOTAL_SCORE > 100000 && TOTAL_SCORE < 250000) {
    var SCORE_PERCENTAGE = ((TOTAL_SCORE - 100000) / (250000 - 100000)) * 100;
    setGlobalVariableValue({
      key: 'SCORE_PERCENTAGE',
      value: SCORE_PERCENTAGE,
    });
    setGlobalVariableValue({ key: 'LEVEL', value: 7 });
    return;
  }
  if (TOTAL_SCORE > 250000 && TOTAL_SCORE < 500000) {
    var SCORE_PERCENTAGE = ((TOTAL_SCORE - 250000) / (500000 - 250000)) * 100;
    setGlobalVariableValue({
      key: 'SCORE_PERCENTAGE',
      value: SCORE_PERCENTAGE,
    });
    setGlobalVariableValue({ key: 'LEVEL', value: 8 });
    return;
  }
  if (TOTAL_SCORE > 500000 && TOTAL_SCORE < 750000) {
    var SCORE_PERCENTAGE = ((TOTAL_SCORE - 500000) / (750000 - 500000)) * 100;
    setGlobalVariableValue({
      key: 'SCORE_PERCENTAGE',
      value: SCORE_PERCENTAGE,
    });
    setGlobalVariableValue({ key: 'LEVEL', value: 9 });
    return;
  }
  if (TOTAL_SCORE > 750000 && TOTAL_SCORE < 1000000) {
    var SCORE_PERCENTAGE = ((TOTAL_SCORE - 750000) / (1000000 - 750000)) * 100;
    setGlobalVariableValue({
      key: 'SCORE_PERCENTAGE',
      value: SCORE_PERCENTAGE,
    });
    setGlobalVariableValue({ key: 'LEVEL', value: 10 });
    return;
  }
  if (TOTAL_SCORE > 1000000) {
    var SCORE_PERCENTAGE = 100;
    setGlobalVariableValue({
      key: 'SCORE_PERCENTAGE',
      value: SCORE_PERCENTAGE,
    });
    setGlobalVariableValue({ key: 'LEVEL', value: 10 });
    return;
  }

  return;
};

export default LEVEL_SCORE;
