import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import getQueryProfileImagePath from '../global-functions/getQueryProfileImagePath';
import getQueryTgId from '../global-functions/getQueryTgId';
import getQueryTgUsername from '../global-functions/getQueryTgUsername';
import getReferralId from '../global-functions/getReferralId';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import waitUtil from '../utils/wait';
import {
  CircleImage,
  LinearGradient,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ImageBackground, Text, View } from 'react-native';

const WelcomeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setGlobalVariableValue({
          key: 'UserImagePath',
          value: getQueryProfileImagePath(),
        });
        setGlobalVariableValue({
          key: 'TgId',
          value: getQueryTgId(),
        });
        setGlobalVariableValue({
          key: 'TgUsername',
          value: getQueryTgUsername(),
        });
        setGlobalVariableValue({
          key: 'Network_id',
          value: getReferralId(),
        });
        await waitUtil({ milliseconds: 3000 });
        navigation.navigate('MainScreen');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasLeftSafeArea={false}
      hasRightSafeArea={false}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images['hookahbg']}
        style={StyleSheet.applyWidth(
          { flex: 1, height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              alignSelf: 'auto',
              flex: 1,
              height: '100%',
            },
            dimensions.width
          )}
        >
          <LinearGradient
            startX={0}
            color1={'rgba(0, 0, 0, 0)'}
            color2={palettes.App.color_1}
            color3={palettes.App.Theme_background}
            endX={0}
            endY={0}
            startY={200}
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: '50%', width: '100%' },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.branding.primary,
                      fontFamily: 'Aladin_400Regular',
                      fontSize: 42,
                      marginTop: 30,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Hookah Token'}
              </Text>
              {/* Text 2 */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Aladin_400Regular',
                      fontSize: 30,
                      marginTop: 10,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'will be lunched on'}
              </Text>
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'contain'}
                source={Images['ton']}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 150, width: 250 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, width: '100%' },
                dimensions.width
              )}
            >
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'cover'}
                source={Images['loader']}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { width: '100%' }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </LinearGradient>

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flex: 1,
                justifyContent: 'flex-end',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <LinearGradient
              startX={0}
              color1={palettes.App.Theme_background_2}
              color2={palettes.App.color_1}
              color3={'rgba(0, 0, 0, 0)'}
              endX={0}
              endY={0}
              startY={100}
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '60%',
                  justifyContent: 'flex-end',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View>
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'Aladin_400Regular',
                        fontSize: 18,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Stay tuned\nMore info in official channels'}
                </Text>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    margin: 20,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <CircleImage size={42} source={Images['x']} />
                {/* Circle Image 2 */}
                <CircleImage size={42} source={Images['telegrambw']} />
                {/* Circle Image 3 */}
                <CircleImage size={42} source={Images['instabw']} />
              </View>

              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Aladin_400Regular',
                      fontSize: 16,
                      marginBottom: 20,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Copyright Hookah © 2024'}
              </Text>
            </LinearGradient>
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(WelcomeScreen);
