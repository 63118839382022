import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as USERApi from '../apis/USERApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import copyTo from '../global-functions/copyTo';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  CircleImage,
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const UserScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [ModalState, setModalState] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      console.log(props.route?.params?.['var'] ?? null);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasLeftSafeArea={false}
      hasRightSafeArea={false}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.Theme_background,
          flexDirection: 'column',
          justifyContent: 'flex-start',
        },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: palettes.App.Theme_background,
            justifyContent: 'space-between',
            marginLeft: 10,
            marginRight: 10,
            paddingBottom: 10,
            paddingTop: 10,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}
        >
          <CircleImage size={48} source={Images['logo1']} />
        </View>

        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: theme.colors.background.brand,
              fontFamily: 'Aladin_400Regular',
              fontSize: 26,
              marginTop: 10,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'Network'}
        </Text>
        {/* Text 2 */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: theme.colors.background.brand,
              fontFamily: 'Aladin_400Regular',
              fontSize: 18,
              marginTop: 10,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {
            'Share your invitation link with your friends to join Hookaah community and earn from each friend added to network points.'
          }
        </Text>
      </View>

      <USERApi.FetchGETNETWORKGET
        handlers={{
          on4xx: fetchData => {
            try {
              navigation.navigate('WelcomeScreen');
            } catch (err) {
              console.error(err);
            }
          },
        }}
        referralId={Constants['Referral_id']}
        userId={Constants['UserId']}
      >
        {({ loading, error, data, refetchGETNETWORK }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <SimpleStyleFlatList
              data={fetchData}
              horizontal={false}
              inverted={false}
              keyExtractor={(listData, index) =>
                listData?.id ??
                listData?.uuid ??
                index?.toString() ??
                JSON.stringify(listData)
              }
              keyboardShouldPersistTaps={'never'}
              listKey={'H2dJ5VEM'}
              nestedScrollEnabled={false}
              numColumns={1}
              onEndReachedThreshold={0.5}
              renderItem={({ item, index }) => {
                const listData = item;
                return (
                  <View
                    style={StyleSheet.applyWidth(
                      { marginLeft: 5, marginRight: 5 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App.Theme_background_2,
                          borderBottomWidth: 1,
                          borderColor: theme.colors.border.brand,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginTop: 12,
                          paddingBottom: 7,
                          paddingLeft: 5,
                          paddingRight: 5,
                          paddingTop: 7,
                        },
                        dimensions.width
                      )}
                    >
                      <View>
                        <CircleImage
                          size={48}
                          source={{
                            uri: `https://hookaah-bot.azurewebsites.net/photo?name=${listData?.profileImagePath}`,
                          }}
                        />
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flex: 1,
                            justifyContent: 'center',
                            marginLeft: 10,
                            marginRight: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Name ~ */}
                        <Text
                          accessible={true}
                          ellipsizeMode={'tail'}
                          numberOfLines={1}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.background.brand,
                              fontFamily: 'Aladin_400Regular',
                              fontSize: 14,
                              marginTop: 4,
                              opacity: 1,
                            },
                            dimensions.width
                          )}
                        >
                          {listData?.tgUsername}
                        </Text>
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            alignSelf: 'auto',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginRight: 5,
                            width: '20%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          disabled={false}
                          ellipsizeMode={'tail'}
                          numberOfLines={1}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                alignSelf: 'auto',
                                color: theme.colors.background.brand,
                                fontFamily: 'Aladin_400Regular',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {listData?.score}
                        </Text>

                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          disabled={false}
                          ellipsizeMode={'tail'}
                          numberOfLines={1}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                alignSelf: 'auto',
                                color: theme.colors.background.brand,
                                fontFamily: 'Aladin_400Regular',
                                marginLeft: 5,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'H'}
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              }}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.applyWidth(
                { flex: 1, flexBasis: 1, flexWrap: 'nowrap', gap: 0 },
                dimensions.width
              )}
            />
          );
        }}
      </USERApi.FetchGETNETWORKGET>
      {/* View 2 */}
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, justifyContent: 'center', maxHeight: '10%' },
          dimensions.width
        )}
      >
        <Button
          iconPosition={'left'}
          onPress={() => {
            try {
              setGlobalVariableValue({
                key: 'BottomBar',
                value: true,
              });
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          icon={'Ionicons/person-add-sharp'}
          iconSize={16}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: theme.colors.branding.primary,
                borderWidth: 2.5,
                fontFamily: 'Montserrat_600SemiBold',
                fontSize: 16,
                marginBottom: 5,
                marginLeft: 15,
                marginRight: 15,
                marginTop: 5,
                paddingBottom: 10,
                paddingTop: 10,
              }
            ),
            dimensions.width
          )}
          title={'Invite\n'}
        />
      </View>
      {/* BottomNavigation_Bar */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App.Theme_background,
            flexDirection: 'row',
            height: '10%',
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App.Theme_background_2,
              borderBottomLeftRadius: 30,
              borderBottomRightRadius: 30,
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginBottom: 25,
              marginLeft: 10,
              marginRight: 10,
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('MainScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'MaterialCommunityIcons/bank'}
              />
            </View>
          </Touchable>
          {/* Touchable 2 */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('TasksScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'MaterialCommunityIcons/clipboard-check-multiple-outline'}
              />
            </View>
          </Touchable>
          {/* Touchable 3 */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('MarketScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'SimpleLineIcons/social-dropbox'}
              />
            </View>
          </Touchable>
          {/* Touchable 4 */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('UserScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'Feather/user'}
              />
            </View>
          </Touchable>
        </View>
      </View>

      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'slide'}
        transparent={true}
        visible={Constants['BottomBar']}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App.Theme_background_2,
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              bottom: 0,
              flex: 0,
              height: '50%',
              justifyContent: 'flex-start',
              position: 'absolute',
              width: '100%',
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'flex-end',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                padding: 15,
              },
              dimensions.width
            )}
          >
            <IconButton
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'BottomBar',
                    value: false,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              color={theme.colors.background.brand}
              icon={'AntDesign/close'}
              size={26}
              style={StyleSheet.applyWidth({ right: 0 }, dimensions.width)}
            />
          </View>

          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: theme.colors.background.brand,
                fontFamily: 'Montserrat_600SemiBold',
                fontSize: 26,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'Invite a friend\n'}
          </Text>
          {/* Button 2 */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  await copyTo(
                    Constants['Referral_id'],
                    'https://t.me/hookaah_bot/Hookaah?startapp='
                  );
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App.Theme_background,
                  borderRadius: 20,
                  fontFamily: 'Montserrat_600SemiBold',
                  fontSize: 22,
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 25,
                  paddingBottom: 15,
                  paddingTop: 15,
                }
              ),
              dimensions.width
            )}
            title={'Copy'}
          />
          {/* Button 4 */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  await WebBrowser.openBrowserAsync(
                    `https://t.me/share/url?url=https://t.me/hookaah_bot/Hookaah?startapp=${Constants['Referral_id']}&text=Welcome to hookaah app`
                  );
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App.Theme_background,
                  borderRadius: 20,
                  fontFamily: 'Montserrat_600SemiBold',
                  fontSize: 22,
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 25,
                  paddingBottom: 15,
                  paddingTop: 15,
                }
              ),
              dimensions.width
            )}
            title={'Send'}
          />
          {/* Button 3 */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                setGlobalVariableValue({
                  key: 'BottomBar',
                  value: false,
                });
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  color: theme.colors.background.brand,
                  fontFamily: 'Montserrat_600SemiBold',
                  fontSize: 22,
                  marginBottom: 20,
                  marginTop: 25,
                }
              ),
              dimensions.width
            )}
            title={'Close'}
          />
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(UserScreen);
