const getQueryProfileImagePath = () => {
  const searchParams = new URLSearchParams(location.search);
  const ProfileImagePath = searchParams.get('profileImagePath');

  if (ProfileImagePath) {
    return ProfileImagePath;
  } else {
    return 'No value';
  }
};

export default getQueryProfileImagePath;
