import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as USERApi from '../apis/USERApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import hookahModalState from '../global-functions/hookahModalState';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  CircleImage,
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  TabView,
  TabViewItem,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const MarketScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [ModalDesText, setModalDesText] = React.useState('');
  const [ModalState, setModalState] = React.useState(false);
  const [ModalTitleText, setModalTitleText] = React.useState('');
  const [On, setOn] = React.useState(false);
  const [Tasks, setTasks] = React.useState([
    { id: 1, title: 'Task_1', subTitle: '+200' },
    { id: 1, title: 'Task_1', subTitle: '+200' },
  ]);
  const [imageModal, setImageModal] = React.useState('');
  const [titleModal, setTitleModal] = React.useState('');
  const HOOKAH_BOTTON_TEXT = isPurshased => {
    if (isPurshased) {
      return 'Select';
    } else {
      return 'Buy';
    }
  };
  const uSERPURSHASEHOOKAHPOST = USERApi.usePURSHASEHOOKAHPOST();
  const uSERSELECTHOOKAHPUT = USERApi.useSELECTHOOKAHPUT();
  const uSERPURSHASEMYSTRYBOXPOST = USERApi.usePURSHASEMYSTRYBOXPOST();

  return (
    <ScreenContainer
      scrollable={false}
      hasLeftSafeArea={false}
      hasRightSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.Theme_background },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: palettes.App.Theme_background,
            justifyContent: 'space-between',
            marginLeft: 10,
            marginRight: 10,
            paddingBottom: 10,
            paddingTop: 10,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}
        >
          <CircleImage size={56} source={Images['logo1']} />
        </View>
        {/* Text 2 */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: '"rgb(255, 255, 255)"',
              fontFamily: 'Aladin_400Regular',
              fontSize: 26,
              marginTop: 10,
            }),
            dimensions.width
          )}
        >
          {'Market'}
        </Text>

        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: theme.colors.background.brand,
              fontFamily: 'Aladin_400Regular',
              fontSize: 16,
              marginTop: 10,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {
            'The Marketplace allows you to buy Mystery Boxes with a chance to earn up to 20,000,000 H Coin.'
          }
        </Text>
      </View>

      <TabView
        iconPosition={'top'}
        indicatorColor={theme.colors.branding.primary}
        initialTabIndex={0}
        keyboardDismissMode={'auto'}
        pressColor={theme.colors.branding.primary}
        scrollEnabled={false}
        swipeEnabled={true}
        tabBarPosition={'top'}
        activeColor={theme.colors.background.brand}
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App.Theme_background,
            fontFamily: 'Aladin_400Regular',
            fontSize: 18,
            marginLeft: 10,
            marginRight: 10,
          },
          dimensions.width
        )}
        tabsBackgroundColor={palettes.App.Theme_background_2}
      >
        {/* Hookah */}
        <TabViewItem
          {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
              { backgroundColor: palettes.App.Theme_background }
            ),
            dimensions.width
          )}
          title={'Hookah'}
        >
          <USERApi.FetchGETHOOKAHLISTGET
            handlers={{
              on4xx: fetchData => {
                try {
                  navigation.navigate('WelcomeScreen');
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            userId={Constants['UserId']}
          >
            {({ loading, error, data, refetchGETHOOKAHLIST }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <SimpleStyleFlatList
                  data={fetchData}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) =>
                    listData?.id ??
                    listData?.uuid ??
                    index?.toString() ??
                    JSON.stringify(listData)
                  }
                  keyboardShouldPersistTaps={'never'}
                  listKey={'wiswqORj'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <>
                        {/* View 7 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignSelf: 'stretch',
                              backgroundColor: palettes.App.Theme_background,
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor:
                                  palettes.App.Theme_background_2,
                                borderBottomWidth: 1,
                                borderColor: theme.colors.border.brand,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginTop: 12,
                                paddingBottom: 18,
                                paddingLeft: 15,
                                paddingRight: 15,
                                paddingTop: 18,
                              },
                              dimensions.width
                            )}
                          >
                            <View>
                              <CircleImage
                                size={56}
                                source={{
                                  uri: `https://hookaah-bot.azurewebsites.net/photo?name=${listData?.iconImagePath}`,
                                }}
                              />
                            </View>

                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1, marginLeft: 10, marginRight: 10 },
                                dimensions.width
                              )}
                            >
                              {/* Name ~ */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.background.brand,
                                    fontFamily: 'Aladin_400Regular',
                                    fontSize: 18,
                                    marginTop: 4,
                                    opacity: 1,
                                  },
                                  dimensions.width
                                )}
                              >
                                {listData?.name}
                              </Text>
                              {/* Message ~ */}
                              <Text
                                accessible={true}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.background.brand,
                                    fontFamily: 'Aladin_400Regular',
                                    fontSize: 14,
                                    marginTop: 4,
                                    opacity: 1,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Price : '}
                                {listData?.price}
                                {' H'}
                              </Text>
                              {/* Message ~ 2 */}
                              <Text
                                accessible={true}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.background.brand,
                                    fontFamily: 'Aladin_400Regular',
                                    fontSize: 14,
                                    marginTop: 4,
                                    opacity: 1,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Earn/Tap : '}
                                {listData?.earnPerTape}
                                {', Earn/Hour : '}
                                {listData?.earnPerHour}
                              </Text>
                            </View>

                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-end' },
                                dimensions.width
                              )}
                            >
                              <Button
                                iconPosition={'left'}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      setImageModal(listData?.iconImagePath);
                                      setTitleModal(listData?.name);
                                      if (listData?.purchased) {
                                        (
                                          await uSERSELECTHOOKAHPUT.mutateAsync(
                                            {
                                              hookahId: listData?.id,
                                              userId: Constants['UserId'],
                                            }
                                          )
                                        )?.json;
                                        setModalDesText('You selected hookah');
                                        setGlobalVariableValue({
                                          key: 'ModalHookahState',
                                          value: true,
                                        });
                                        hookahModalState(
                                          setGlobalVariableValue
                                        );
                                      } else {
                                        const result1 = (
                                          await uSERPURSHASEHOOKAHPOST.mutateAsync(
                                            {
                                              hookahId: listData?.id,
                                              userId: Constants['UserId'],
                                            }
                                          )
                                        )?.json;
                                        console.log(result1);
                                        setModalDesText(result1);
                                        setGlobalVariableValue({
                                          key: 'ModalHookahState',
                                          value: true,
                                        });
                                        hookahModalState(
                                          setGlobalVariableValue
                                        );
                                      }
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                {...GlobalStyles.ButtonStyles(theme)['Button']
                                  .props}
                                disabled={listData?.selected}
                                disabledOpacity={0.2}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)['Button']
                                      .style,
                                    {
                                      borderRadius: 20,
                                      fontFamily: 'System',
                                      fontSize: 14,
                                      fontWeight: '600',
                                      width: 55,
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={`${HOOKAH_BOTTON_TEXT(
                                  listData?.purchased
                                )}
`}
                              />
                            </View>
                          </View>
                          <Divider
                            {...GlobalStyles.DividerStyles(theme)['Divider']
                              .props}
                            color={theme.colors.text.medium}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.DividerStyles(theme)['Divider']
                                  .style,
                                { height: 2 }
                              ),
                              dimensions.width
                            )}
                          />
                        </View>
                      </>
                    );
                  }}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'auto', flex: 1, flexBasis: 1, gap: 0 },
                    dimensions.width
                  )}
                />
              );
            }}
          </USERApi.FetchGETHOOKAHLISTGET>
        </TabViewItem>
        {/* MysteryBox */}
        <TabViewItem
          {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
            dimensions.width
          )}
          title={'Mystery Box'}
        >
          <USERApi.FetchGETMYSTRYBOXGET
            handlers={{
              on4xx: fetchData => {
                try {
                  navigation.navigate('WelcomeScreen');
                } catch (err) {
                  console.error(err);
                }
              },
            }}
          >
            {({ loading, error, data, refetchGETMYSTRYBOX }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <SimpleStyleFlatList
                  data={fetchData}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) =>
                    listData?.id ??
                    listData?.uuid ??
                    index?.toString() ??
                    JSON.stringify(listData)
                  }
                  keyboardShouldPersistTaps={'never'}
                  listKey={'xNV0WTE7'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <>
                        {/* View 7 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignSelf: 'stretch',
                              backgroundColor: palettes.App.Theme_background,
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor:
                                  palettes.App.Theme_background_2,
                                borderBottomWidth: 1,
                                borderColor: theme.colors.border.brand,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginTop: 12,
                                paddingBottom: 18,
                                paddingLeft: 15,
                                paddingRight: 15,
                                paddingTop: 18,
                              },
                              dimensions.width
                            )}
                          >
                            <View>
                              <CircleImage
                                size={56}
                                source={{
                                  uri: `https://hookaah-bot.azurewebsites.net/photo?name=${listData?.imagePath}`,
                                }}
                              />
                            </View>

                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1, marginLeft: 10, marginRight: 10 },
                                dimensions.width
                              )}
                            >
                              {/* Name ~ */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.background.brand,
                                    fontFamily: 'Aladin_400Regular',
                                    fontSize: 18,
                                    marginTop: 4,
                                    opacity: 1,
                                  },
                                  dimensions.width
                                )}
                              >
                                {listData?.name}
                              </Text>
                              {/* Message ~ */}
                              <Text
                                accessible={true}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.background.brand,
                                    fontFamily: 'Aladin_400Regular',
                                    fontSize: 14,
                                    marginTop: 4,
                                    opacity: 1,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Price : '}
                                {listData?.price}
                                {' H'}
                              </Text>
                              {/* Message ~ 2 */}
                              <Text
                                accessible={true}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.background.brand,
                                    fontFamily: 'Aladin_400Regular',
                                    fontSize: 14,
                                    marginTop: 4,
                                    opacity: 1,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Max Earn : '}
                                {listData?.earnMax}
                                {' H'}
                              </Text>
                            </View>

                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-end' },
                                dimensions.width
                              )}
                            >
                              <Button
                                iconPosition={'left'}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      setImageModal(listData?.imagePath);
                                      setTitleModal(listData?.name);
                                      const result = (
                                        await uSERPURSHASEMYSTRYBOXPOST.mutateAsync(
                                          {
                                            mystryboxId: listData?.id,
                                            userId: Constants['UserId'],
                                          }
                                        )
                                      )?.json;
                                      console.log(result);
                                      setModalDesText(result);
                                      setGlobalVariableValue({
                                        key: 'ModalHookahState',
                                        value: true,
                                      });
                                      hookahModalState(setGlobalVariableValue);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                {...GlobalStyles.ButtonStyles(theme)['Button']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)['Button']
                                      .style,
                                    {
                                      borderRadius: 20,
                                      fontFamily: 'System',
                                      fontSize: 14,
                                      fontWeight: '600',
                                      width: 55,
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'Buy\n'}
                              />
                            </View>
                          </View>
                          <Divider
                            {...GlobalStyles.DividerStyles(theme)['Divider']
                              .props}
                            color={theme.colors.text.medium}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.DividerStyles(theme)['Divider']
                                  .style,
                                { height: 2 }
                              ),
                              dimensions.width
                            )}
                          />
                        </View>
                      </>
                    );
                  }}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'auto', flex: 1, flexBasis: 1, gap: 0 },
                    dimensions.width
                  )}
                />
              );
            }}
          </USERApi.FetchGETMYSTRYBOXGET>
        </TabViewItem>
      </TabView>
      {/* BottomNavigation_Bar */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App.Theme_background,
            flexDirection: 'row',
            height: '10%',
            justifyContent: 'space-between',
            position: 'relative',
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App.Theme_background_2,
              borderBottomLeftRadius: 30,
              borderBottomRightRadius: 30,
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginBottom: 25,
              marginLeft: 10,
              marginRight: 10,
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('MainScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'MaterialCommunityIcons/bank'}
              />
            </View>
          </Touchable>
          {/* Touchable 2 */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('TasksScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'MaterialCommunityIcons/clipboard-check-multiple-outline'}
              />
            </View>
          </Touchable>
          {/* Touchable 3 */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('MarketScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'SimpleLineIcons/social-dropbox'}
              />
            </View>
          </Touchable>
          {/* Touchable 4 */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('UserScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={theme.colors.background.brand}
                name={'Feather/user'}
              />
            </View>
          </Touchable>
        </View>
      </View>

      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'slide'}
        transparent={true}
        visible={Constants['ModalHookahState']}
      >
        <View
          style={StyleSheet.applyWidth(
            { backgroundColor: '"rgba(0, 0, 0, 0.42)"', flex: 1 },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App.Theme_background_2,
                height: '40%',
                marginLeft: 20,
                marginRight: 20,
                top: '25%',
              },
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              <CircleImage
                size={80}
                source={{
                  uri: `https://hookaah-bot.azurewebsites.net/photo?name=${imageModal}`,
                }}
                style={StyleSheet.applyWidth(
                  { marginBottom: 10, marginTop: 20 },
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors.background.brand,
                      fontFamily: 'Aladin_400Regular',
                      fontSize: 24,
                    }
                  ),
                  dimensions.width
                )}
              >
                {titleModal}
              </Text>
              {/* Text 2 */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      alignSelf: 'center',
                      color: theme.colors.background.brand,
                      fontFamily: 'Aladin_400Regular',
                      fontSize: 26,
                      marginLeft: 27,
                      marginRight: 27,
                      marginTop: 20,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {ModalDesText}
              </Text>
            </View>
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(MarketScreen);
